.statistics-card-grid {
    width: 100%;
    display: flex;
}

.statistics-card-grid--column {
    width: 100%;
    margin-right: 7px;
}
.statistics-card-grid--column:last-child {
    margin-right: 0;
}

.statistics-card-grid--column div:last-child {
    margin-top: 7px;
}
.statistics-card-grid--column div:first-child {
    margin-top: 0;
}