.statistic-card {
    width: 100%;
    background: var(--gradient-blue);
    border-radius: 21px;
    color: #fff;
    text-align: center;
    box-sizing: border-box;
}

.statistic-card-warning {
    background: var(--gradient-red) !important;
}

.statistic-card p {
    color: #fff;
    font-weight: 600;
    font-size: 14px;
}

.statistic-card span {
    color: #fff;
    font-weight: 400;
    font-size: 16px;
}

.animation-down {
    animation-name: animation-down;
    animation-duration: .5s;
    animation-fill-mode: forwards;
}
@keyframes animation-down {
    0% {
        opacity: 0;
        transform: translateY(-50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.animation-up {
    animation-name: animation-up;
    animation-duration: .5s;
    animation-fill-mode: forwards;
}
@keyframes animation-up {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}