.card-community {
    width: 100%;
    padding: 21px;
    box-sizing: border-box;
    border-radius: 28px;
    background: var(--gradient-blue-forward);
    display: flex;
}

.card-community .left {
    width: 96px;
}
.card-community .right {
    width: calc(100% - 96px);
    padding: 0 0 0 14px;
}
.card-community .right h2 {
    color: #fff;
}
.card-community .right p {
    color: #fff;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-top: 7px;
}

.card-community .right button {
    margin-top: 14px;
}

.card-community .left img {
    margin-top: -75px;
    margin-left: -60px;
    width: 190px;
}