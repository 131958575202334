.icon-button {
    width: 45px;
    height: 45px;
    background: transparent;
    border-radius: 14px;
    border: var(--tabbar-border);
    text-align: center;
    margin-left: 10px;
    transition: transform .2s;
}

.icon-button-2 {
    opacity: .5 !important;
    background: var(--card-color) !important;
}
.icon-button-2 .Icon {
    color: var(--white-or-black) !important;
}

.icon-button-3 {
    opacity: 1 !important;
    background: var(--card-color) !important;
}
.icon-button-3 .Icon {
    color: var(--color-red) !important;
}

.icon-button:active {
    transform: scale(.95);
}

.icon-button .Icon {
    margin: 8.5px auto 0 auto;
    color: var(--color-blue);
}