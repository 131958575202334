.ellipse {
    min-width: 208px;
    width: 208px;
    height: 228px;
    text-align: center;
    cursor: pointer;
}

.ellipse--back {
    width: 208px;
    height: 228px;
    background: transparent;
    transition: transform .2s;
}
.ellipse--back:active {
    transform: scale(.95);
}

.ellipse-shadow {
    width: 100%;
    height: 100%;
    border-radius: 70px;
    background: transparent;
}

.ellipse-shadow-blue {
    box-shadow: 0 16px 30px rgba(85, 199, 255, 0.35);
}
.ellipse-shadow-orange {
    box-shadow: 0 16px 30px rgba(254, 167, 64, 0.35);
}
.ellipse-shadow-red {
    box-shadow: 0 16px 30px rgba(254, 167, 64, 0.35);
}

.ellipse p {
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    color: #fff;
}

.ellipse--blue {
    background: var(--gradient-blue);
}
.ellipse--blue img {
    width: 119px;
    margin-top: -20px;
    position: relative;
}

.ellipse--orange {
    background: var(--gradient-orange);
}
.ellipse--orange img {
    margin-top: 22px;
    width: 120px;
}
.ellipse--orange p {
    margin-top: 23px;
}

.ellipse--red {
    background: var(--gradient-red);
}
.ellipse--red img {
    margin-top: 15px;
    width: 135px;
}
.ellipse--red p {
    margin-top: 18px;
    font-size: 18px;
}