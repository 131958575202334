.timer-panel {
    width: 100%;
    box-sizing: border-box;
    padding: 50px 21px;
    background: var(--card-color);
    border-radius: 42px 42px 0 0;
    text-align: center;
    transition: min-height 1s;
}

.timer-panel h1 {
    font-weight: 700;
    font-size: 21px;
    color: var(--white-or-black);
}

.progressbar {
    width: 100%;
    height: 10px;
    border-radius: 100px;
    background: #DDF3FF;

    animation-name: progressbar-animation;
    animation-duration: .5s;
    animation-fill-mode: forwards;
}
@keyframes progressbar-animation {
    0% {
        transform: translateY(100px);
    }
    100% {
        transform: translateY(0);
    }
}

.progressbar .progress {
    height: 100%;
    background: var(--gradient-blue);
    border-radius: 100px;
    transition: transform .2s;
}