.switch {
    width: 50px;
    height: 31px;
    border-radius: 100px;
    background: var(--color-blue);
    opacity: .2;
    padding: 3px;
    box-sizing: border-box;
    cursor: pointer;
}

.switch-button {
    width: 25px;
    height: 25px;
    background: #fff;
    border-radius: 100px;

    transition: transform .2s;
    transform: translateX(0);
}

.switch-active {
    background: var(--gradient-blue) !important;
    opacity: 1 !important;
}

.switch-button--active-animation {
    transition: transform .2s;
}
.switch-button--active {
    transform: translateX(19px);
}