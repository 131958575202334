.cell {
    width: 100%;
    height: 31px;
    display: flex;
}

.cell .switch {
    margin-left: auto;
}

.cell p {
    font-weight: 400;
    font-size: 14px;
}