body[scheme=space_gray], [scheme=space_gray], .vkuispace_gray {
    /*dark theme*/
    --accent: var(--color-blue) !important;
    --background_content: var(--color-black) !important;
    --header_background: var(--color-black) !important;

    --tabbar-color: #23283C;
    --tabbar-border: 1px solid rgba(255, 255, 255, 0.1);
    --border: 1px solid rgba(0, 0, 0, 0.05);
    --card-color: #23283C;
    --white-or-black: #fff;
    --black-or-white: var(--color-black);
    --modal_card_background: var(--background_content);
    --button-gray: #23283C;
}

[scheme=space_gray] {
    --header_background: var(--color-black) !important;
}

:root, body[scheme=bright_light], [scheme=bright_light], .vkuibright_light {
    /*light theme*/
    --accent: var(--color-blue) !important;
    --tabbar_active_icon: var(--color-red) !important;
    --tabbar_inactive_icon: #9396A2 !important;

    --tabbar-color: #FCFCFC;
    --tabbar-border: 1px solid rgba(0, 0, 0, 0.05);
    --border: 1px solid rgba(0, 0, 0, 0.05);
    --card-color: #fff;
    --white-or-black: var(--color-black);
    --black-or-white: #fff;
    --button-gray: rgba(42, 48, 72, 0.05);
}

body[scheme=vkcom_light], [scheme=vkcom_light], .vkuivkcom_light {
    /*vk.com*/
    --accent: var(--color-blue) !important;
}


.vkui__root, .vkui__portal-root {
    --font-common: var(--font-name), sans-serif !important;
    --font-tt: var(--font-name), sans-serif !important;
    --font-display: var(--font-name), sans-serif !important;
}

.vkuiDiv--android {
    padding: 0 21px !important;
}

.vkuiTabbar--android.vkuiTabbar--shadow, .vkuiTabbar--vkcom.vkuiTabbar--shadow {
    box-shadow: none !important;
    background: var(--tabbar-color) !important;
    border-top: var(--tabbar-border) !important;
}

.vkuiTooltip__content {
    background: var(--gradient-red) !important;
}
.vkuiTooltip__corner {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDIwIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMCAwQzEzLjUgMCAxNS41IDggMjAgOEgwQzQuNSA4IDYuNSAwIDEwIDBaIiBmaWxsPSIjRkY3QjVCIi8+Cjwvc3ZnPgo=") !important;
}

.vkuiTooltip__container {
    animation-name: tooltip-animation;
    animation-duration: .3s;
}
@keyframes tooltip-animation {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.vkuiModalRoot__mask {
    background: rgba(26, 36, 55, .5) !important;
    backdrop-filter: blur(8px) !important;
}

.vkuiModalCard__in {
    padding: 28px 14px !important;
}

.vkuiModalCard__container {
    padding: 35px 21px !important;
}

.vkuiModalCard--android .vkuiModalCard__container, .vkuiModalCard--vkcom .vkuiModalCard__container {
    border-radius: 21px !important;
    box-shadow: none !important;
}

.first_loading .vkuiPopoutWrapper__overlay {
    background: var(--black-or-white) !important;
}

.vkuiPanel.vkuiPanel--regular .vkuiPanel__in, .vkuiPanel.vkuiPanel--regular::after {
    background-color: var(--background_content) !important;
}

/*
.vkuiPanelHeader__in {
    background: rgba(255, 255, 255, 0.8) !important;
    backdrop-filter: blur(10px) !important;
}
.vkuiPanelHeader--trnsp .vkuiPanelHeader__in {
    background: transparent !important;
}*/

.vkuiEpic .vkuiSnackbar {
    padding-bottom: 0 !important;
}

.vkuiSnackbar--android .vkuiSnackbar__body, .vkuiSnackbar--vkcom .vkuiSnackbar__body {
    border-radius: 21px;
}
