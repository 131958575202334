.achievements-card {
    width: 145px;
    min-width: 145px;
    padding: 0 14px 14px 14px;
    box-sizing: border-box;
    border-radius: 28px;
    background: var(--card-color);
    margin-right: 14px;
    text-align: center;
    border: 1px solid rgba(42, 48, 72, 0.05);
}
.achievements-card p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: var(--white-or-black);
}