button {
    border: none;
    outline: none;
    border-radius: 100px;
    padding: 10px 25px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    transition: transform .2s;
}

button:active {
    transform: scale(.95);
}

.button--default {
    background: var(--gradient-red);
    color: #fff;
}

.button--blue {
    background: var(--gradient-blue);
    color: #fff;
}

.button--gray {
    background: var(--button-gray);
    color: var(--white-or-black);
}

.button--stretched {
    box-sizing: border-box;
    width: 100%;
    height: 47px;
}