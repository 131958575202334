.list-item {
    width: 100%;
    margin-bottom: 7px;
    display: flex;
}

.list-item--image {
    width: 62px;
}
.list-item--image img {
    width: 100%;
}

.list-item--text {
    width: calc(100% - 76px);
    margin-left: 14px;
}
.list-item--text h2 {
    color: #fff;
}
.list-item--text p {
    font-size: 14px;
    margin-top: 7px;
    color: #fff;
}