* {
    --color-blue: #56C3FF;
    --color-orange: #FFB041;
    --color-red: #FF7B5B;
    --color-black: #2A3048;

    --gradient-red: linear-gradient(180deg, #FF7B5B 0%, #FFA05B 100%);
    --gradient-blue: linear-gradient(143.26deg, #56C1FF 0%, #4CDDFD 43.23%, #4CDDFD 56.25%, #45B8FB 100%);
    --gradient-blue-forward: linear-gradient(143.26deg, #4CDDFD 0%, #45B8FB 43.23%, #45B8FB 56.25%, #4CDDFD 100%);
    --gradient-orange: linear-gradient(143.26deg, #FEA740 0%, #FEC940 43.23%, #FEC940 56.25%, #FEA740 100%);

    --font-name: 'Montserrat';
}

h1, h2, h3, p, span {
    margin: 0;
    color: var(--white-or-black);
    user-select: none;
}

img {
    -moz-user-select: none;
    -o-user-select:none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

h1 {
    font-weight: 700;
    font-size: 35px;
}

h2 {
    font-weight: 700;
    font-size: 21px;
}

p {
    font-weight: 400;
    font-size: 14px;
}


.slider {
    width: 100%;
    overflow-x: scroll;
}

.ellipse-select .slider-content {
    width: 680px;
    height: 278px;
}

.slider-content {
    display: flex;
    padding: 0 21px;
}
.ellipse-select .slider-content div {
    margin-right: 28px;
}

.slider::-webkit-scrollbar {
    width: 0;
}


.background--premium {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    /*background: var(--gradient-blue);*/
    background: linear-gradient(180deg, #2A3048 0%, #000000 100%);
    z-index: -100;
}

.background--premium .circles {
    width: 100%;
    height: 100%;
    background-image: url("../img/circles-background.png");
    background-repeat: no-repeat;
    background-position-x: 65%;
    background-position-y: 60%;
    opacity: .3;
}

.premium-title {
    margin-top: 14px;
}

.premium-title h1 {
    color: #fff;
}

.premium-title p {
    margin-top: 14px;
    font-size: 21px;
    color: #fff;
}

.list {
    margin-top: 35px;
}


.background--onbording {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--color-black);
    z-index: -100;
}

.background--onbording .circles {
    width: 100%;
    height: 100%;
    background-image: url("../img/circles-background-onbording.png");
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 80%;
    opacity: .3;
}

.onbording {
    text-align: center;
}

.onbording img {
    margin-top: 8vh !important;
    width: 60%;
}

.onbording-1 img {
    max-width: 265px;
    animation-name: onbording-img-1;
    animation-duration: .6s;
}
.onbording-2 img {
    max-width: 201px;
    animation-name: onbording-img-2;
    animation-duration: .6s;
}
.onbording-3 img {
    max-width: 229px;
    animation-name: onbording-img-3;
    animation-duration: .6s;
}
@keyframes onbording-img-1 {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}
@keyframes onbording-img-2 {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}
@keyframes onbording-img-3 {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}

.vkuiEpic .onbording .vkuiFixedLayout--bottom {
    padding-bottom: 0 !important;
}

.vkuiEpic .timer-bottom .vkuiFixedLayout--bottom {
    padding-bottom: 0 !important;
}

.vkuiEpic .premium-bottom .vkuiFixedLayout--bottom {
    padding-bottom: 35px !important;
}

.header-word {
    font-weight: 500 !important;
    font-size: 17px !important;
    color: #fff !important;
    opacity: .5;
    user-select: none;
    cursor: pointer;
    margin-left: 12px;
}

.onbording-1 .onbording-panel {
    animation-name: onbording-panel-1;
    animation-duration: .3s;
}
.onbording-2 .onbording-panel {
    animation-name: onbording-panel-2;
    animation-duration: .3s;
}
.onbording-3 .onbording-panel {
    animation-name: onbording-panel-3;
    animation-duration: .3s;
}
@keyframes onbording-panel-1 {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}
@keyframes onbording-panel-2 {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}
@keyframes onbording-panel-3 {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}


.background--subscribe {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, #2A3048 0%, #0C0D14 100%);
    z-index: -100;
}

.image-container {
    width: 100%;
    margin-top: 8vh;
    text-align: center;
}

@media screen and (max-height: 685px) {
    .image-container {
        margin-top: 5vh;
    }
}

.image-background {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    background: var(--color-blue);
    margin: 0 auto;
}

.image-container img {
    width: 319px;
    margin-top: -265px;
}

@media screen and (max-height: 640px) {
    .image-background {
        width: 150px;
        height: 150px;
    }
    .image-container img {
        width: 240px;
        margin-top: -210px;
    }
}

.texts h1 {
    color: #fff;
}
.texts p {
    color: #fff;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    margin-top: 21px;
}


.background--timer {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -100;
}
.background--timer .circles {
    width: 100%;
    height: 100%;
    background-image: url("../img/circles-background-timer.png");
    background-repeat: no-repeat;
    background-position-x: 55%;
    background-position-y: 50%;
    opacity: .3;
}
.background-mask {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--color-black);
    opacity: .5;
}


.timer {
    text-align: center;
    margin-top: 27vh;
}
.timer h1 {
    font-weight: 700;
    /*font-size: 50px;*/
    font-size: 70px;
    color: #fff;
}


.hide-animation {
    animation-name: hide-animation;
    animation-duration: .5s;
    animation-fill-mode: forwards;
}
@keyframes hide-animation {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.timer-finish {
    text-align: center;
    margin-top: 19vh;

    animation-name: timer-finish;
    animation-duration: .5s;
    animation-fill-mode: forwards;
}
@keyframes timer-finish {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.timer-finish h1 {
    font-weight: 700;
    font-size: 50px;
    color: #fff;
}
.timer-finish p {
    font-weight: 400;
    font-size: 18px;
    color: #fff;
    margin-top: 14px;
}
.timer-finish button {
    margin-top: 28px;
}


.cell-list {
    margin-top: 14px;
}
.cell-list .cell {
    margin-bottom: 7px;
}