.onbording-panel {
    width: 100%;
    box-sizing: border-box;
    padding: 50px 21px;
    background: var(--gradient-blue-forward);
    border-radius: 42px 42px 0 0;
}

.onbording-panel h1 {
    font-weight: 700;
    color: #fff;
    font-size: 28px;
}

.onbording-panel p {
    font-weight: 400;
    font-size: 18px;
    color: #fff;
    margin-top: 14px;
}

.onbording-panel button {
    margin-top: 28px;
}