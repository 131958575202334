.card {
    width: 100%;
    padding: 21px;
    box-sizing: border-box;
    border-radius: 28px;
    background: var(--card-color);
    display: flex;
    border: 1px solid rgba(42, 48, 72, 0.05);
}

.card .left {
    width: 96px;
}
.card .left img {
    width: 100%;
}
.card .right {
    width: calc(100% - 96px);
    padding: 0 0 0 14px;
}
.card .right p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-top: 7px;
}

.card .right button {
    margin-top: 14px;
}